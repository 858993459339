
/**
 * Measure data bubble display information
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { ProjectThreshold } from "@/interface/projectsInterface";
import { InformationBubbleData } from "@/interface/RadarSection";
import { RadarData } from "@/model/ModelSectionState";
import ModelUser from "@/model/ModelUser";
import UserAccess from "@/model/User/UserAccess";
import { TrafficVolumeData } from "@/services/evaluatorService";
import { formatNumberInt } from "@/utils/FormatNumberInt";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  name: "MeasureInformationBubble",
  computed: {
    ...mapGetters(["getUserAccess", "getCurrentUser"]),
    ...mapGetters("projects", {
      currentThreshold: "GetCurrentThreshold"
    }),
    ...mapGetters("Evaluator", { GET_SECTION_TRAFFIC_VOLUME: "GET_SECTION_TRAFFIC_VOLUME" })
  }
})
export default class MeasureInformationBubble extends Vue {
  /*----------  Props  ----------*/
  @Prop({ type: Number, default: 0 }) sectionId!: number;
  @Prop({ type: Number, default: 0 }) occupiedSpeedAverage!: number;

  @Prop({ type: Boolean, default: false }) isRZP!: boolean;
  @Prop({ type: Boolean, default: false }) isCWU!: boolean;
  @Prop({ type: Boolean, default: false }) isMq3Aq32SW!: boolean;
  @Prop({ type: Boolean, default: false }) isMq4Aq42SWDEGES!: boolean;
  @Prop({ type: Boolean, default: false }) isMq4Aq42SW!: boolean;
  @Prop({ type: Boolean, default: false }) isMq5Aq52SW!: boolean;
  @Prop({ type: Boolean, default: false }) isCwuCascaded!: boolean;
  @Prop({ type: Boolean, default: false }) isMq1Aq12SW!: boolean;

  @Prop({ type: Boolean, default: false }) isMainProject!: boolean;
  @Prop({ type: Object as () => InformationBubbleData }) mainProjectData!: InformationBubbleData;

  @Prop({ type: Object as () => RadarData, default: () => ({}) }) importantData!: RadarData;

  /*----------  Vuex  ----------*/

  readonly getUserAccess!: UserAccess;
  readonly getCurrentUser!: ModelUser;

  private readonly currentThreshold!: ProjectThreshold;
  formatNumberInt = formatNumberInt;

  // eslint-disable-next-line no-unused-vars
  GET_SECTION_TRAFFIC_VOLUME!: (id: number | null) => TrafficVolumeData;

  mostImportantRadarData: RadarData = { averageSpeed: "", occupancy: "", name: "" };

  get getTrafficJamStateClass() {
    let avgSpeed = parseFloat(this.importantData.averageSpeed);
    let occupancy = parseFloat(this.importantData.occupancy);

    let lowSpeedThreshold = 35;
    let midSpeedThreshold = 50;
    let occupancyThreshold = 17;

    if (this.isCWU || this.isCwuCascaded) {
      lowSpeedThreshold = this.currentThreshold.low;
      midSpeedThreshold =
        this.isMq3Aq32SW || this.isMq4Aq42SWDEGES || this.isMq4Aq42SW || this.isMq5Aq52SW || this.isMq1Aq12SW
          ? this.currentThreshold.high
          : this.currentThreshold.mid;
      occupancyThreshold = this.currentThreshold.occupancyLimit;
    }
    if (this.isRZP) {
      lowSpeedThreshold = 30;
      midSpeedThreshold = 55;
    }

    if (avgSpeed === 0) return occupancy < occupancyThreshold ? "traffic-jam-free" : "traffic-jam";
    return avgSpeed < lowSpeedThreshold && avgSpeed > 0
      ? "traffic-jam"
      : avgSpeed >= lowSpeedThreshold && avgSpeed < midSpeedThreshold
      ? "traffic-jam-risk"
      : "traffic-jam-free";
  }

  get dataToDisplay(): InformationBubbleData {
    if (this.isMainProject) {
      return this.mainProjectData;
    }
    return {
      getTrafficJamStateClass: this.getTrafficJamStateClass,
      averageSpeed: this.importantData.averageSpeed as unknown as number,
      occupancy: this.importantData.occupancy as unknown as number,
      laneName: this.importantData.name,
      totalVolumePerHour: formatNumberInt(
        this.GET_SECTION_TRAFFIC_VOLUME(this.sectionId).totalVolumePerHour,
        this.getCurrentUser.getLocale
      )
    };
  }

  removeKMHandConvertToFloat(formattedAverageSpeed: string) {
    return parseFloat(formattedAverageSpeed?.slice(0, -4));
  }

  formatNumber(
    number: number | string,
    _settings: Record<string, any> = { style: "unit", unit: "kilometer-per-hour", maximumFractionDigits: 2 }
  ) {
    return new Intl.NumberFormat(this.getCurrentUser.getLocale, _settings).format(Number(number));
  }
}
