
/**
 * Schematic section For the camera section view
 *
 *
 * @author IboMade GmbH
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import { Component, Mixins } from "vue-property-decorator";
import { mapGetters } from "vuex";

import KmFormatted from "@/mixins/Project/KilometrageFromatted";
import Project from "@/model/Project";
import SingleDeviceHeader from "./SingleDeviceHeader.vue";
import { SectionStateDTO } from "@/model/ModelSectionState";
import UserAccess from "@/model/User/UserAccess";
import { calculateDeviceStatus, isTheScreenOff, getConnectionStrengthImageName } from "@/utils/DeviceHelper";

@Component({
  name: "CameraTypeSchematic",
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      allSectionStates: "GetProjectSectionsStatesForMap"
    })
  },
  components: { SingleDeviceHeader }
})
export default class CameraTypeSchematic extends Mixins(KmFormatted) {
  readonly currentProject!: Project;
  private readonly allSectionStates!: Array<SectionStateDTO>;
  readonly getUserAccess!: UserAccess;

  batteryClass = "low";
  cbRadioOne = false;

  deviceNetworkConnectionQualityASU = "0";
  netType = "";

  get getSectionOneStatus() {
    let sectionState = this.allSectionStates.find(
      (state: SectionStateDTO) => state.sectionId === this.schematicInformation.id
    );
    if (sectionState) {
      const state = sectionState.state.stateDetails.deviceStateOne;
      /*----------  Cb Radio Specific  ----------*/
      this.cbRadioOne = state.cbRadio;

      /*----------  Battery Specific ----------*/
      let batteryValueOne = state.deviceBatteryState?.batteryTerminalValue || 0;
      this.batteryClass = calculateDeviceStatus(batteryValueOne);

      const displayOneIsOff = isTheScreenOff(this.batteryClass, state.status, state.online);
      displayOneIsOff ? (this.cbRadioOne = false) : null;

      /*----------  Network connection state  ----------*/
      this.deviceNetworkConnectionQualityASU = getConnectionStrengthImageName(state.signalLevelAsu || 0).toString();
      this.netType = state.netType;
    }

    if (this.schematicInformation.state.stateDetails.deviceStateOne.supportEnabled) return "SUPPORT";
    else return this.schematicInformation.state.stateDetails.deviceStateOne?.status;
  }

  get isDefaultDirection(): boolean {
    return this.schematicInformation.direction === "DEFAULT";
  }

  openDisplayModalDetails(displayInformation: any) {
    if (displayInformation.vwa) {
      this.getUserAccess.Access.VIEW.project.vwa.settings = true;
    } else {
      this.getUserAccess.Access.VIEW.project.vwa.settings = false;
    }

    this.$bvModal.show("openDisplayModalDetails");
    this.$emit("openDisplayModalDetails", displayInformation);
  }

  dynamicStyles(name: string) {
    if (name === "none.svg" || !name) return;
    let image = require(`@/assets/images/projects/RZP_BG/options/png/lines-${name}`);
    return image;
  }

  getBadgeVariant() {
    const status = this.getSectionOneStatus || "ALERT";

    const states = {
      SUPPORT: "info",
      OK: "active",
      WARNING: "pending",
      WAITING: "inactive",
      ALERT: "banned"
    };
    return states[status];
  }
}
