
/**
 * Single header for the schematic view
 * the battery and the cb-radio display
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  name: "SingleDeviceHeader"
})
export default class SingleDeviceHeader extends Vue {
  /*----------  Props  ----------*/
  @Prop({ default: false, type: Boolean }) showCbRadioIcon!: boolean;
  @Prop({ default: 1, type: Number}) batteryNumber!: number;
  @Prop({ default: "low", type: String }) batteryClass!: string;
  @Prop({ default: "0", type: String }) networkSignalClass!: string;
  @Prop({ default: "", type: String }) netType!: string;
  @Prop({ default: false, type: Boolean }) cbRadioStatus!: boolean;
}
