
/**
 * Schematic View Header title and information for the project.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

interface Time {
  start: string;
  end: string;
}
import UserAccess from "@/model/User/UserAccess";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
@Component({
  name: "SchematicViewHeader",
  computed: {
    ...mapGetters(["getUserAccess"])
  }
})
export default class SchematicViewHeader extends Vue {
  @Prop({ type: String, default: "Schematic section" }) ProjectName!: string;
  @Prop({ type: Object, default: () => ({}) }) time!: Time;
  @Prop({ type: Boolean, default: false }) isCWU!: boolean;
  @Prop({ type: Boolean, default: false }) isMq3Aq3!: boolean;
  @Prop({ type: Boolean, default: false }) isMq3Aq32SW!: boolean;
  @Prop({ type: Boolean, default: false }) isMq4Aq42SWDEGES!: boolean;
  @Prop({ type: Boolean, default: false }) isMq3Aq32023!: boolean;
  @Prop({ type: Boolean, default: false }) isArchived!: boolean;
  @Prop({ type: Boolean, default: false }) isMq4Aq42SW!: boolean;
  @Prop({ type: Boolean, default: false }) isMq5Aq52SW!: boolean;
  @Prop({ type: Boolean, default: false }) isMq1Aq12SW!: boolean;

  @Prop({ type: String, default: "Start - End Information" })
  startEndInformation!: string;
  readonly getUserAccess!: UserAccess;
}
