var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    !_vm.currentProject.isMq3Aq3 &&
    !_vm.currentProject.isMq3Aq32SW &&
    !_vm.currentProject.isMq4Aq42SWDEGES &&
    !_vm.currentProject.isMq4Aq42SW &&
    !_vm.currentProject.isMq5Aq52SW &&
    !_vm.currentProject.isMq3Aq32023
  )?_c('b-col',{staticClass:"mx-auto mb-4 p-0",attrs:{"cols":"12","lg":"12"}},[_c('h4',{staticClass:"text-muted mb-2"},[_vm._v(_vm._s(_vm.$t("project.schematicView.updateThresholdForm.title")))]),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("project.schematicView.updateThresholdForm.direction", { directionEnd: _vm.currentProject.directionNameEnd }))+" ")]),_c('b-table',{staticClass:"custom-shadow custom-scroll",attrs:{"small":"","bordered":"","show-empty":"","striped":"","responsive":"","hover":"","thead-class":"d-none","items":_vm.currentThresholdTable}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }