




























































































































/**
 * Update the Threshold for CWU type of projects.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [ ] Update the typescript.
 */

import { Component, Vue } from "vue-property-decorator";
import ThresholdTable from "@/components/project/SingleProject/Schematic/ThresHold/ThresholdTable.vue";
import { mapActions, mapGetters } from "vuex";
import Project from "@/model/Project";
import UserAccess from "@/model/User/UserAccess";

interface thresholds {
  high: any | null;
  mid: any | null;
  low: any | null;
  occupancyLimit: any | null;
  observationPeriod: any | null;
  occupancyFactor: any | null;
}

@Component({
  name: "UpdateThreshold",
  components: {
    ThresholdTable
  },
  computed: {
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      currentThresholdTable: "GetCurrentThresholdTable"
    }),
    ...mapGetters(["getUserAccess"])
  },
  methods: {
    ...mapActions("projects", {
      updateThreshold: "UpdateThreshold",
      getThreshold: "GetCurrentThreshold",
      getProjectDetails: "DetailsOfProject",
      getThresholdTableData: "getCurrentThresholdTable"
    })
  }
})
export default class UpdateThreshold extends Vue {
  // Vuex
  readonly currentProject!: Project;
  readonly getUserAccess!: UserAccess;

  private updateThreshold!: Function;
  private getProjectDetails!: Function;
  private getThreshold!: Function;
  private getThresholdTableData!: Function;

  // Data

  localThresholds: thresholds = {
    high: null,
    mid: null,
    low: null,
    occupancyLimit: null,
    observationPeriod: null,
    occupancyFactor: null
  };

  isLoading: boolean = false;
  updateThresholdStatus: any = null;

  mounted() {
    this.setLocalThresholdValue();
  }

  /*---------- computed  ----------*/
  confirmModal(msg: string, action: Function) {
    this.$bvModal
      .msgBoxConfirm(msg, {
        title: (this as any).$t("project.section.displayInterval.confirmModals.title"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        if (value) {
          action();
        }
      });
  }

  handleUpdateThreshold() {
    this.updateThresholdStatus = null;

    this.confirmModal((this as any).$t("project.schematicView.updateThresholdForm.confirmModal"), async () => {
      this.isLoading = true;

      this.currentProject.isMq1Aq1HYST ? (this.localThresholds.mid = this.localThresholds.high) : "";
      const updateStatus = await this.updateThreshold({
        id: this.currentProject.id,
        thresholdToUpdate: this.localThresholds
      });
      if (updateStatus.status === 200) {
        this.updateThresholdStatus = {
          class: "success",
          msg: this.$t("project.schematicView.updateThresholdForm.updateSuccess")
        };
        this.resetAndProject();
      } else {
        this.updateThresholdStatus = {
          class: "danger",
          msg: updateStatus
        };
      }
    });
  }

  async resetAndProject() {
    this.getProjectDetails(this.currentProject.id).then(() => {
      this.isLoading = false;
      this.setLocalThresholdValue();
    });
  }

  setLocalThresholdValue() {
    this.getThreshold(this.$route.params.id).then((res: { status: number; data: thresholds }) => {
      if (res.status === 200) {
        this.localThresholds = res.data;
      }
    });
    this.getThresholdTableData(this.$route.params.id);
  }
}
