
import Section from "@/model/ModelSection";
/**
 * Single section schematic view
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";

import InformationType from "@/components/project/SingleProject/Schematic/SinlgeViewTypes/InformationType.vue";
import RadarType from "@/components/project/SingleProject/Schematic/SinlgeViewTypes/RadarType.vue";
import DisplayType from "@/components/project/SingleProject/Schematic/SinlgeViewTypes/DisplayType.vue";
import CameraType from "@/components/project/SingleProject/Schematic/SinlgeViewTypes/CameraType.vue";
import VwaType from "@/components/project/SingleProject/Schematic/SinlgeViewTypes/VwaType.vue";

import RibbonItem from "@/components/shared/RibbonItem.vue";
import { Type } from "@/interface/DeviceInterface";
import UserAccess from "@/model/User/UserAccess";

@Component({
  name: "SingleSectionSchematic",
  components: {
    InformationType,
    RadarType,
    DisplayType,
    CameraType,
    VwaType,
    RibbonItem
  },
  methods: {
    ...mapActions(["getAllDevicesFromBackEnd"]),
    ...mapActions("projects", {
      getSectionDetailsFromBackEnd: "GetSectionDetail"
    })
  },
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters(["getDeviceBySerialNumber"]),
    ...mapGetters("projects", {
      displayTypes: "GetDisplayTypesList"
    })
  }
})
export default class SingleSectionSchematic extends Vue {
  /*----------  Props  ----------*/
  @Prop({ type: Object, default: () => new Section() }) section!: Section;

  readonly getUserAccess!: UserAccess;
  /*----------  Vuex  ----------*/
  private getAllDevicesFromBackEnd!: Function;
  private getSectionDetailsFromBackEnd!: Function;

  private readonly displayTypes!: Array<Type>;
  private readonly getDeviceBySerialNumber!: Function;

  // TODO: Make a getter to get the section type and return the component name

  /*----------  Methods  ----------*/
  openEditForm(item: Section) {
    this.getAllDevicesFromBackEnd().then(() => {
      // @ts-ignore
      item.type.label = this.$t(`project.sectionOptions.${item.type?.name}`);

      let defaultDisplayAndBoards: any = {};
      defaultDisplayAndBoards.displayOne = item.displayOneType
        ? this.displayTypes.find((device) => device.id == item.displayOneType?.id)
        : null;
      defaultDisplayAndBoards.displayTwo = item.displayTwoType
        ? this.displayTypes.find((device) => device.id == item.displayTwoType?.id)
        : null;
      defaultDisplayAndBoards.boardOne = this.getDeviceBySerialNumber(item.boardOne);
      defaultDisplayAndBoards.boardTwo = this.getDeviceBySerialNumber(item.boardTwo);

      // Get Interval
      if (item.type?.name === "display") {
        this.getSectionDetailsFromBackEnd(item.id);
        // this.getIntervalFromTheBackEnd(item.id);
      }
      this.$emit("open-edit-modal", { item, defaultDisplayAndBoards });
    });
  }
}
