import { render, staticRenderFns } from "./SingleDeviceHeader.vue?vue&type=template&id=95d5766c&scoped=true&"
import script from "./SingleDeviceHeader.vue?vue&type=script&lang=ts&"
export * from "./SingleDeviceHeader.vue?vue&type=script&lang=ts&"
import style0 from "./SingleDeviceHeader.vue?vue&type=style&index=0&id=95d5766c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95d5766c",
  null
  
)

export default component.exports