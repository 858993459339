<template>
  <multiselect
    v-model="value"
    :options="allOptions"
    :placeholder="$t('projects.singleProjectSections.createSection.sectionDevicePlaceHolder')"
    :searchable="true"
    track-by="serialNumber"
    label="serialNumber"
    :show-labels="false"
    @input="$emit('value-change', { value, name })"
  >
    <template slot="singleLabel" slot-scope="props">
      <span class="option__desc">
        <span class="option__title">{{ props.option.serialNumber }}-{{ props.option.state }}</span>
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span class="option__title">{{ props.option.serialNumber }} - {{ props.option.state }}</span>
      </div>
    </template>
    <template slot="noResult">{{ $t("utils.multiselect.noResult") }}</template>
    <template slot="noOptions">
      <span>{{ $t("projects.singleProjectSections.createSection.sectionDeviceNoOptionPlaceHolder") }}</span>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "SelectDropDownAdd",
  components: {
    Multiselect
  },
  props: {
    allOptions: {
      type: Array,
      default: function () {
        return [];
      }
    },
    name: {
      type: String,
      default: "device"
    }
  },
  data: function () {
    return {
      value: ""
    };
  }
};
</script>

<style></style>
