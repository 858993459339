
/**
 * Schematic view top  Information type Section.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [✔] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import { Component, Mixins } from "vue-property-decorator";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";
import { mapGetters } from "vuex";
import Project from "@/model/Project";
import UserAccess from "@/model/User/UserAccess";

@Component({
  name: "InformationType",
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject"
    })
  }
})
export default class InformationType extends Mixins(KmFormatted) {
  currentProject!: Project;
  readonly getUserAccess!: UserAccess;

  /*----------  Computed properties  ----------*/
  get isDefaultDirection(): boolean {
    return this.schematicInformation.direction === "DEFAULT";
  }

  // webUrl = process.env.VUE_APP_WEB_URL;
  // backgroundImage = "none.svg";

  /*----------  Get dinamic svg image  ----------*/

  dynamicStyles(name: string) {
    if (name === "none.svg" || !name) return;
    return require(`@/assets/images/projects/RZP_BG/options/png/lines-${name}`);
  }
}
