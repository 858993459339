
/**
 * Start and end position in the schematic View.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
@Component({
  name: "StartAndEndSchematic"
})
export default class StartAndEnd extends Vue {
  @Prop({ type: String, default: "" }) position!: string;
  @Prop({ type: String, default: "left" }) sectionDirection!: string;
  @Prop({ type: Boolean, default: false }) isRZP!: boolean;
}
