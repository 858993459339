
/**
 * Threshold table for projects type cwu.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Project from "@/model/Project";
import Vue from "vue";
import Component from "vue-class-component";
import { mapGetters, mapActions } from "vuex";

@Component({
  name: "ThresholdTable",
  computed: {
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject",
      currentThresholdTable: "GetCurrentThresholdTable"
    })
  },
  methods: {
    ...mapActions("projects", {
      getThresholdTableData: "getCurrentThresholdTable"
    })
  }
})
export default class ThresholdTable extends Vue {
  /******Vuex *****/
  currentProject!: Project;
  currentThresholdTable!: [];

  private getThresholdTableData!: Function;

  mounted() {
    this.getThresholdTableData(this.$route.params.id);
  }
}
