<template>
  <b-col cols="12" lg="12">
    <b-card class="border-0 overflow-hidden schematic-container" no-body>
      <!-- Schematic section header -->
      <SchematicViewHeader
        v-if="currentProject"
        :isCWU="currentProject.isCWU"
        :startEndInformation="currentProject.getFormattedStartAndEndInformation"
        :ProjectName="currentProject.name"
        :time="{ start: currentProject.dateStart, end: currentProject.dateEnd }"
        :isArchived="!currentProject.isArchived"
        :isMq3Aq3="currentProject.isMq3Aq3"
        :isMq3Aq32SW="currentProject.isMq3Aq32SW"
        :isMq4Aq42SWDEGES="currentProject.isMq4Aq42SWDEGES"
        :isMq4Aq42SW="currentProject.isMq4Aq42SW"
        :isMq3Aq32023="currentProject.isMq3Aq32023"
        :isMq5Aq52SW="currentProject.isMq5Aq52SW"
        :isCwuCascaded="currentProject.isCwuCascaded"
        :isMq1Aq12SW="currentProject.isMq1Aq12SW"
      />
      <!-- Threshold Table  -->
      <b-col cols="12" class="px-4">
        <ThresholdTable v-if="currentProject.isCWU" />
        <!-- Schematic view -->
        <RZPSceneOptions v-if="currentProject.isRZP" />
        <ScenesOptions v-if="currentProject.isSCENE" />
        <!--  RZP show the second  schematic view -->
        <b-row
          no-gutters
          class="flex-nowrap py-5 overflow-auto direction-opposite custom-scroll"
          v-if="currentProject.isRZP"
        >
          <StartAndEnd
            :position="currentProject.directionNameStart"
            sectionDirection="right"
            :isRZP="currentProject.isRZP"
          />
          <SingleSectionSchematic
            v-on:open-edit-modal="openEditForm"
            v-on:open-display-modal-details="openDisplayModalDetails"
            v-on:open-image-modal="openCameraSectionImage"
            :section="section"
            v-for="section in CurrentProjectOppositeSections"
            :key="section.id"
          />
          <StartAndEnd
            :position="currentProject.directionNameEnd"
            sectionDirection="right"
            :isRZP="currentProject.isRZP"
          />
        </b-row>
        <b-col cols="12" class="my-3 p-0" v-if="currentProject.isRZP">
          <div class="p-2 bg-light"></div>
        </b-col>

        <b-row
          no-gutters
          class="flex-nowrap py-5 overflow-auto custom-scroll"
          :class="{ 'is-RZP': currentProject.isRZP }"
        >
          <StartAndEnd :position="currentProject.directionNameStart" :isRZP="currentProject.isRZP" />
          <SingleSectionSchematic
            v-on:open-edit-modal="openEditForm"
            v-on:open-display-modal-details="openDisplayModalDetails"
            v-on:open-image-modal="openCameraSectionImage"
            :section="section"
            v-for="section in CurrentProjectSectionList"
            :key="section.id"
          />
          <StartAndEnd :position="currentProject.directionNameEnd" :isRZP="currentProject.isRZP" />
        </b-row>
      </b-col>
      <!-- Schematic items view end  -->
    </b-card>
    <!-- Modal state  status information modal-->
    <SingleBoardInformationModal
      :sectionId="GET_MODAL_INFO.sectionId"
      :boardPosition="GET_MODAL_INFO.position"
      v-on:infoModalClose="informationModalClose"
      v-on:refresh-schematic-view="refreshSchematicView"
    />
    <!-- EditSection Modal  -->
    <b-modal
      v-if="getUserAccess.Access.EDIT.project.sectionListTab.editSection"
      id="EditSectionModal"
      @close="updateDeviceList"
      @hide="updateDeviceList"
      size="xl"
      body-class="p-0"
      header-class="bg-light border-0"
      :title="$t('project.schematicView.modalsTitle.updateSectionModalTitle')"
      hide-footer
    >
      <EditSectionModal
        :sectionToEdit="sectionToEdit"
        v-on:updateDeviceList="updateDeviceList"
        v-on:updateSectionInformation="updateSectionInformation"
        :defaultDisplayAndBoards="defaultDisplayAndBoards"
        :sectionUpdateError="sectionUpdateError"
        :updatingSection="isSectionUpdating"
      />
    </b-modal>
    <!-- Camera image modal -->
    <b-modal
      id="cameraSectionImage"
      @close="RESET_CAMERA_PICTURE"
      @hide="RESET_CAMERA_PICTURE"
      header-class="bg-light border-0"
      centered
      size="xl"
      :title="$t('project.schematicView.modalsTitle.cameraSectionImageModalTitle')"
      hide-footer
    >
      <CameraSectionImageModal v-if="cameraSectionInformation" :sectionInfo="cameraSectionInformation" />
    </b-modal>
  </b-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SchematicViewHeader from "@/components/project/SingleProject/Schematic/SchematicViewHeader";
import EditSectionModal from "@/components/project/SingleProject/Sections/EditSectionModal";

import ThresholdTable from "@/components/project/SingleProject/Schematic/ThresHold/ThresholdTable.vue";
// Start and end schematic
import StartAndEnd from "@/components/project/SingleProject/Schematic/StartAndEndSchematic.vue";

import CameraSectionImageModal from "@/components/project/SingleProject/Schematic/Camera/CameraSectionImageModal.vue";
import SingleSectionSchematic from "@/components/project/SingleProject/Schematic/SingleSectionSchematic.vue";
import RZPSceneOptions from "@/components/project/SingleProject/Schematic/RoadZipper/RZPSceneOptions.vue";
import ScenesOptions from "@/components/project/SingleProject/Schematic/scenes/ScenesOptions.vue";
import SingleBoardInformationModal from "@/components/project/SingleProject/Schematic/InformationModal/SingleBoardInformationModal.vue";
import SectionToUpdate from "@/model/SingleProject/SectionUpdateDto";

export default {
  name: "SchematicSections",
  components: {
    SchematicViewHeader,
    EditSectionModal,
    ThresholdTable,
    StartAndEnd,
    SingleBoardInformationModal,
    CameraSectionImageModal,
    RZPSceneOptions,
    ScenesOptions,
    SingleSectionSchematic
  },
  data: () => ({
    sectionUpdateError: null,
    sectionToEdit: null,
    defaultDisplayAndBoards: {
      displayOne: null,
      displayTwo: null,
      boardTwo: null,
      boardOne: null
    },
    cameraSectionInformation: null,
    isSectionUpdating: false
  }),

  computed: {
    ...mapGetters(["getUserAccess", "getDeviceBySerialNumber"]),
    ...mapGetters("projects", {
      allSectionTypes: "GetSectionTypes",
      displayTypes: "GetDisplayTypesList",
      currentProject: "GetCurrentProject",
      displayInformation: "GetCurrentDisplayInformation",
      currentSectionState: "GetCurrentSectionState",
      currentSectionDetails: "GetCurrentSectionDetails",
      CurrentProjectSectionList: "GetSectionList",
      CurrentProjectOppositeSections: "GetAllOppositeSections",
      GET_MODAL_INFO: "GET_MODAL_INFO"
    })
  },
  methods: {
    ...mapMutations("projects", {
      RESET_CAMERA_PICTURE: "ResetCameraSectionLatestPicture",
      RESET_INFORMATION_MODAL: "ResetInformationModal",
      SET_MODAL_OPEN: "SET_MODAL_OPEN"
    }),
    ...mapActions(["getAllDevicesFromBackEnd"]),
    ...mapActions("projects", {
      UpdateSection: "UpdateSection",
      refreshSectionAllSections: "GetAllSectionFromTheProject",
      getSectionDetailsFromBackEnd: "GetSectionDetail",
      getIntervalFromTheBackEnd: "GetSectionInterval",
      getDisplayDetails: "GetDisplayDetails",
      GetCurrentSectionState: "GetCurrentSectionState"
    }),
    openEditForm({ item, defaultDisplayAndBoards }) {
      this.sectionToEdit = item;
      this.defaultDisplayAndBoards = defaultDisplayAndBoards;
      this.$bvModal.show("EditSectionModal");
    },
    updateDeviceList() {
      this.refreshSectionAllSections(this.currentProject.id);
      this.$bvModal.hide("EditSectionModal");
      this.sectionToEdit = null;
      this.defaultDisplayAndBoards = {
        displayOne: null,
        displayTwo: null,
        boardTwo: null,
        boardOne: null
      };
    },
    async updateSectionInformation() {
      this.$emit("UpdateStatus", null);
      this.sectionUpdateError = null;
      try {
        this.isSectionUpdating = true;
        this.sectionToEdit.displayOneType = this.defaultDisplayAndBoards.displayOne
          ? this.defaultDisplayAndBoards.displayOne
          : this.sectionToEdit.displayOne;
        this.sectionToEdit.displayTwoType = this.defaultDisplayAndBoards.displayTwo
          ? this.defaultDisplayAndBoards.displayTwo
          : this.sectionToEdit.displayTwo;

        this.sectionToEdit.boardOne = this.defaultDisplayAndBoards.boardOne
          ? this.defaultDisplayAndBoards.boardOne.serialNumber
          : null;
        this.sectionToEdit.boardTwo = this.defaultDisplayAndBoards.boardTwo
          ? this.defaultDisplayAndBoards.boardTwo.serialNumber
          : null;
        this.sectionToEdit.projectId = this.currentProject.id;

        const sectionFormatted = new SectionToUpdate(this.sectionToEdit).FormatToUpdate();
        const res = await this.UpdateSection(sectionFormatted);
        if (res.status === 200) {
          this.$emit("UpdateStatus", {
            class: "success",
            msg: this.$t("projects.singleProjectView.statusMsg.updateSectionSuccess")
          });
          this.updateDeviceList();
        } else {
          const translation = this.$t(res.label).toString();
          const fallbackOrTranslationCheck = res.label === translation ? res.message : translation;
          this.sectionUpdateError = {
            class: "danger",
            msg: fallbackOrTranslationCheck
          };
          this.$emit("UpdateStatus", {
            class: "danger",
            msg: this.$t("projects.singleProjectView.statusMsg.updateSectionError")
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isSectionUpdating = false;
      }
    },

    openDisplayModalDetails({ displayPosition, sectionInfo }) {
      this.SET_MODAL_OPEN({ sectionId: sectionInfo.id, position: displayPosition });
    },
    openCameraSectionImage(SectionInformation) {
      this.cameraSectionInformation = SectionInformation;
      this.$bvModal.show("cameraSectionImage");
    },

    informationModalClose() {
      this.SET_MODAL_OPEN();
    },
    refreshSchematicView() {
      this.refreshSectionAllSections(this.currentProject.id);
    }
  }
};
</script>
<style lang="scss"></style>
